<template>
  <div class="main_container">
    <div class="row mb-3 mt-2 bread-crumbs">
      <div class="text-left col-12">
        <span>Rapports > Détails Levée de Fond</span>
      </div>
    </div>
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1200"
                  filename="Rapport_details_Levee_De_Fond"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="700px"
                  :pdf-margin="10"
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf">
      <!-- <section slot="pdf-content"> -->
        
      <div slot="pdf-content"
           style="padding-left:50px;padding-right:50px;padding-top: 100px;">
        <div class="row justify-content-center my-4">
          <div class="col-8 mx-auto">
            <div class="card title-card">
              <div class="card-body text-center title">
                Détails de levées de fond
              </div>
            </div>
          </div>
        </div>   
        <table class="table tablestyle_3 table-striped">
          <thead>
            <tr>
              <th class="th-blue"
                  scope="col">
                Prêteur
              </th>
              <th class="th-blue"
                  scope="col">
                Nature de la dette
              </th>
              <th class="th-blue"
                  scope="col">
                Objet de la dette
              </th>
              <th class="th-blue"
                  scope="col">
                Montant du prêt (FCFA)
              </th>
              <th class="th-blue"
                  scope="col">
                Date fin remboursement
              </th>
              <th class="th-blue"
                  scope="col">
                Nature du prêt 
              </th>
              <th class="th-blue"
                  scope="col">
                Date mise en place
              </th>
              <th class="th-blue"
                  scope="col">
                Période de différé en assurance
              </th>
              <th class="th-blue"
                  scope="col">
                Période de différé en principal
              </th>
              <th class="th-blue"
                  scope="col">
                Période de différé en intérêt
              </th>
              <th class="th-blue"
                  scope="col">
                Période de différé en TOB
              </th>
              <th class="th-blue"
                  scope="col">
                Durée de remboursement 
              </th>
              <th class="th-blue"
                  scope="col">
                Montant du tirage (FCFA)
              </th>
              <th class="th-blue"
                  scope="col">
                Date de fin de remboursement
              </th>
              <th class="th-blue"
                  scope="col">
                Garantie accordée
              </th>
              <th class="th-blue"
                  scope="col">
                Maturité
              </th>
              <th class="th-blue"
                  scope="col">
                Nature garantie
              </th>
              <th class="th-blue"
                  scope="col">
                Périodicité
              </th>
              <th class="th-blue"
                  scope="col">
                Taux d'intérêt
              </th>
            </tr>
          </thead>
          <tbody v-if="!loaded">
            <tr>
              <td class="text-center"
                  colspan="20">
                Chargement ...
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="long != 0">
            <tr v-for="(levee, lkey) in leveefond.donnees"
                :key="lkey"
                @click="launchDetails(levee.id)">
              <td>{{ levee.preteur.sigle }}</td>
              <td>{{ levee.nature_dette }}</td>
              <td>{{ levee.objet_dette }}</td>
              <td class="text-right">{{ Math.round(levee.montant_pret).toLocaleString() }}</td>
              <td class="text-center">{{ levee.date_fin_remboursement }}</td>
              <td>{{ levee.nature_pret }}</td>
              <td>{{ levee.date_mise_place }}</td>
              <td>{{ levee.periode_differe_en_assurence }}</td>
              <td>{{ levee.periode_differe_en_principal }}</td>
              <td>{{ levee.periode_differe_en_interet }}</td>
              <td>{{ levee.periode_differe_en_tob }}</td>
              <td class="text-right">{{ levee.duree_remboursement }}</td>
              <td class="text-right">{{ Math.round(Number(levee.montant_tirages)).toLocaleString() }}</td>
              <td class="text-center">{{ levee.date_fin_remboursement }}</td>
              <td>{{ levee.garantie_accordee }}</td>
              <td>{{ levee.maturite }}</td>
              <td>{{ levee.nature_garantie }}</td>
              <td>{{ levee.periodicite }}</td>
              <td class="text-right">{{ Math.round(levee.taux_interet * 100) / 100 }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6"
                  class="text-center">
                Aucune levée de fonds enregistrée
              </td>
            </tr>
          </tbody>
        </table>
          
      </div>
      <!-- </section> -->
    </vue-html2pdf>
    <!-- END of Vue-html2pdf  -->
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Détails de levées de fond
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
  
    <div class="col-md-4 mx-auto">
      <!-- <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisir la banque</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="bank"
                    @change="changeBank(bank)">
              <option value=0>Tout</option>      
              <option v-for="(compte, ckey) in comptes.donnees"
                      :value="compte.banque.id"
                      :key="ckey">{{ compte.banque.single }}</option>
            </select>
          </div>

        </div> -->
    </div>
    
    <!--- /row my-4 --->
    
    <div class="row my-4">
      <table class="table tablestyle_3 table-striped">
        <thead>
          <tr>
            <th class="th-blue"
                scope="col">
              Prêteur
            </th>
            <th class="th-blue"
                scope="col">
              Nature de la dette
            </th>
            <th class="th-blue"
                scope="col">
              Objet de la dette
            </th>
            <th class="th-blue"
                scope="col">
              Montant du prêt (FCFA)
            </th>
            <th class="th-blue"
                scope="col">
              Date fin remboursement
            </th>
            <th class="th-blue"
                scope="col">
              Nature du prêt 
            </th>
            <th class="th-blue"
                scope="col">
              Date mise en place
            </th>
            <th class="th-blue"
                scope="col">
              Période de différé en assurance
            </th>
            <th class="th-blue"
                scope="col">
              Période de différé en principal
            </th>
            <th class="th-blue"
                scope="col">
              Période de différé en intérêt
            </th>
            <th class="th-blue"
                scope="col">
              Période de différé en TOB
            </th>
            <th class="th-blue"
                scope="col">
              Durée de remboursement 
            </th>
            <th class="th-blue"
                scope="col">
              Montant du tirage (FCFA)
            </th>
            <th class="th-blue"
                scope="col">
              Date de fin de remboursement
            </th>
            <th class="th-blue"
                scope="col">
              Garantie accordée
            </th>
            <th class="th-blue"
                scope="col">
              Maturité
            </th>
            <th class="th-blue"
                scope="col">
              Nature garantie
            </th>
            <th class="th-blue"
                scope="col">
              Périodicité
            </th>
            <th class="th-blue"
                scope="col">
              Taux d'intérêt
            </th>
          </tr>
        </thead>
        <tbody v-if="!loaded">
          <tr>
            <td class="text-center"
                colspan="20">
              Chargement ...
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="long != 0">
          <tr v-for="(levee, lkey) in leveefond.donnees"
              :key="lkey"
              @click="launchDetails(levee.id)">
            <td>{{ levee.preteur.sigle }}</td>
            <td>{{ levee.nature_dette }}</td>
            <td>{{ levee.objet_dette }}</td>
            <td class="text-right">{{ Math.round(levee.montant_pret).toLocaleString() }}</td>
            <td class="text-center">{{ levee.date_fin_remboursement }}</td>
            <td>{{ levee.nature_pret }}</td>
            <td>{{ levee.date_mise_place }}</td>
            <td>{{ levee.periode_differe_en_assurence }}</td>
            <td>{{ levee.periode_differe_en_principal }}</td>
            <td>{{ levee.periode_differe_en_interet }}</td>
            <td>{{ levee.periode_differe_en_tob }}</td>
            <td class="text-right">{{ levee.duree_remboursement }}</td>
            <td class="text-right">{{ Math.round(Number(levee.montant_tirages)).toLocaleString() }}</td>
            <td class="text-center">{{ levee.date_fin_remboursement }}</td>
            <td>{{ levee.garantie_accordee }}</td>
            <td>{{ levee.maturite }}</td>
            <td>{{ levee.nature_garantie }}</td>
            <td>{{ levee.periodicite }}</td>
            <td class="text-right">{{ Math.round(levee.taux_interet * 100) / 100 }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6"
                class="text-center">
              Aucune levée de fonds enregistrée
            </td>
          </tr>
        </tbody>
      </table>
    </div> 
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import utils from "@/assets/js/utils"
import VueHtml2pdf from 'vue-html2pdf'
// import Notif from "@/components/shared/Toast"
// import ListFilter from "@/components/shared/Filter"

export default {
  name: "leveeDeFond",
  components:{
    VueHtml2pdf,
    // ListFilter,
    // Notif
  },
  data: () => ({
    leveeData: {
      libelle: "",
      order: "",
      tri: ""
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    filtre:[],
    chmp:[
      {
        valeur:"sigle",
        type:"caractere",
        table:"banques",
        libelle:"Prêteur",
      },
      {
        valeur:"nature_dette",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Nature de la dette",
      },
      {
        valeur:"objet_dette",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Objet de la dette",
      },
      {
        valeur:"montant_pret",
        type:"numeric",
        table:"levee_fonds",
        libelle:"Montant du prêt",
      },
      {
        valeur:"date_fin_remboursement",
        type:"date",
        table:"levee_fonds",
        libelle:"Date fin remboursement",
      },
      {
        valeur:"nature_pret",
        type:"caractere",
        table:"levee_fonds",
        libelle:"Nature du prêt",
      },
    ],
    user:"",
    url:"",
    listExtensionFiles:"",
    uploadfile:"",
    inputFiles: {  
      donnees_histo:"" 
    },
    form: form({
      donnees_histo:"" 
    })
      .rules({
        donnees_histo:"required" 
      })
      .messages({
        donnees_histo:"Ce champ est requis" 
      }),
    index: 1,
    long: "",
    goodUrl: "",
    loaded: false
  }),
  watch: {
    leveefond() {
      if (this.leveefond.donnees) {
        this.loaded = true
        this.long = this.leveefond.donnees.length
      }
      //console.log(this.long)
    },
    successLevee(){
      if (this.successLevee) {
        this.notif.message = this.successLevee
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.setLeveeFond("")
            this.leveeFonds(null)
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    errorsLevee(){
      if (this.errorsLevee) {
        this.notif.message = this.errorsLevee
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setError("")
            this.setFiles("")
            // this.$refs["modal_button"].click()
            this.$refs["donnees_histo"].value=""
            this.inputFiles["donnees_histo"]=""
          }.bind(this),
          3000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
      }
    }
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.leveeFonds(null)
    this.listExtensionFiles = utils.formaFichierAccepte
  },
  computed: {
    ...mapGetters(["leveefond","files", "errorsMsg","successLevee","errorsLevee"])
  },
  methods: {
    ...mapActions(["leveeFonds","saveFile", "pageLevees","importDette"]),
    ...mapMutations(["setLeveeFond","setSuccess","setError","setFiles"]),
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    launchDetails(id) {
      this.$router.push({ name: "DetailLevee", params: { id: id } })
    },
    paging(url) {
      if (this.index == 0) {
        this.index = 1
      }
      this.goodUrl = url.split("/")
      //console.log(this.goodUrl)
      url = this.goodUrl[1]
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.pageLevees(url)
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.setLeveeFond("")
      this.leveeFonds(this.filtre)
    },
    viderFile(){
      this.inputFiles.donnees_histo=''
      this.setFiles('')
      this.$refs["donnees_histo"].value=""
    },
    restoring() {
      this.loaded = false
      this.long = null
      this.leveeData = { libelle: "", order: "", tri: "" }
      this.leveeFonds(null)
    },
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      // this.uploadfile = filename
      const extension = this.inputFiles[filename].split(".")[1]

      if (this.listExtensionFiles.includes(extension)) {
        // this.loading[filename] = true
        // this.disabled = true
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])
      } else {
        this.inputFiles[filename] = ""
        this.notif.message = "Mauvais format du fichier !!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    submit() {
      this.importDette({url:this.url})
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"public/files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>
